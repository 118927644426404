var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 特店類型 "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":_vm.$t("common['Search']"),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canEdit)?_c('span',{staticClass:"pt-5"},[_c('v-btn',{staticClass:"\n                  font-weight-normal\n                  text-capitalize\n                  btn-primary\n                  bg-gradient-secondary\n                  py-3\n                  px-6\n                  ms-3\n                ",on:{"click":function($event){return _vm.onEditItem(null)}}},[_vm._v(" "+_vm._s(_vm.$t("common[\"Add\"]"))+" ")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"loading":!_vm.isLoaded,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"search":_vm.search,"sort-by":['id'],"sort-desc":[false],"footer-props":{
                'items-per-page-text': _vm.$t("common['Rows per page']"),
              },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdAt ? _vm.moment .unix(item.createdAt) .format("YYYY/MM/DD HH:mm:ss") : ""))])]}},{key:"item.actions",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"dark":"","fab":"","x-small":"","color":"blue darken-2"},on:{"click":function($event){return _vm.gotoPage(props.item)}}},on),[_c('v-icon',[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("查看特店列表")])]),(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"dark":"","fab":"","x-small":"","color":"cyan"},on:{"click":function($event){return _vm.onEditItem(props.item)}}},on),[_c('v-icon',[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("編輯")])]):_vm._e()]}}])})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.tableData.length,"loading":!_vm.isLoaded,"show-items-per-page":false}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.openEditDialog),callback:function ($$v) {_vm.openEditDialog=$$v},expression:"openEditDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.isNew ? _vm.$t("common[\"Add\"]") : _vm.$t("common[\"Edit\"]"))+" ")]),_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("sidebar[\"Store Categories\"]"))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"editForm",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"特店類型","rules":_vm.rules.required},model:{value:(_vm.editItem.name),callback:function ($$v) {_vm.$set(_vm.editItem, "name", $$v)},expression:"editItem.name"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeEditDialog}},[_vm._v(" "+_vm._s(_vm.$t("common['Close']"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSaveEditItem}},[_vm._v(" "+_vm._s(_vm.$t("common['Save']"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }